import cx from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const baseStyle = "group inline-block min-w-[216px]";

type Props = {
	children: React.ReactNode;
	className?: string | Record<string, boolean>;
	disabled?: boolean;
	onClick?: () => void;
	to?: string;
	href?: string;
};

export const CtaButton = ({ disabled, onClick, to, href, ...props }: Props) => {
	const inner = React.useMemo(() => <Inner {...props} />, [props]);

	if (href)
		return (
			<a
				href={href}
				rel="noopener noreferrer"
				target="_blank"
				className={cx(baseStyle)}
			>
				{inner}
			</a>
		);

	if (to)
		return (
			<Link to={to} className={baseStyle}>
				{inner}
			</Link>
		);

	return (
		<button
			disabled={disabled}
			type="button"
			className={cx(baseStyle, {
				"pointer-events-none": disabled,
			})}
			onClick={onClick}
		>
			{inner}
		</button>
	);
};

function Inner({ className, children }: Pick<Props, "children" | "className">) {
	return (
		<span className="grid">
			<span className="col-span-full row-span-full">
				<StaticImage
					src="../images/btn-on.png"
					alt=""
					layout="fullWidth"
					className="hidden group-enabled:group-hover:block"
				/>
				<StaticImage
					src="../images/btn-off.png"
					alt=""
					layout="fullWidth"
					className="group-enabled:group-hover:hidden"
				/>
			</span>
			<span
				className={cx(
					"col-span-full row-span-full z-10 font-bold pb-[4.62963%] lg:pb-[3.703704%]",
					"text-2xl lg:text-4xl flex items-center justify-center",
					className
				)}
			>
				{children}
			</span>
		</span>
	);
}
