import { CtaButton } from "components/CtaButton";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Links } from "libs/constants";
import React from "react";

export const Reserve = () => {
	const { t, language } = useI18next();

	return <CtaButton href={language === 'en' ? Links.DownloadEn : Links.Download}>{t("button.download")}</CtaButton>;
};
