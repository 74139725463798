import { AgeWarningModal } from "components/AgeWarningModal";
import { Copyright } from "components/Copyright";
import { Footer } from "components/Footer";
import { GradientCoverContainer } from "components/GradientCoverContainer";
import { Header } from "components/Header";
import { Meteor } from "components/Meteor";
import { Seo } from "components/Seo";
import { SpaceShip } from "components/SpaceShip";
import { PageProps } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import * as React from "react";

export default function Root({
	pageContext,
	children,
}: PageProps<null, { layout: string; language: string }>) {
	const { language } = useI18next();

	if (pageContext.layout === "no-layout") return <>{children}</>;

	return (
		<>
			<Seo />
			<AgeWarningModal />
			<Header />
			<Meteor />
			<GradientCoverContainer>
				{children}
				<Footer />
			</GradientCoverContainer>
			{language === "zh-tw" && <Copyright />}
			<SpaceShip />
		</>
	);
}
