export enum Links {
	Twitter = "https://twitter.com/StarLusts",
	Facebook = "https://www.facebook.com/StarLusts/",
	Discord = "https://discord.gg/j6wktsjPwz",
	LinkTree = "https://linktr.ee/starlusts",
	Download = "http://bit.ly/3hL81Ld",
	DownloadEn = "https://www.nutaku.net/games/star-lusts/",
	Home = "/#billboard",
	WorldOverview = "/#world-overview",
	Feature = "/#feature",
	Announcements = "/announcements",
	Characters = "/characters",
	FAQ = "/faq",
}
