import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

export function Copyright() {
	// noinspection AllyJsxHardcodedStringInspection
	return (
		<section className="flex flex-col items-center px-4 py-6 text-sm bg-black lg:flex-row lg:justify-center lg:py-10">
			<StaticImage
				src="../images/18.jpg"
				alt="18+ 限制級"
				className="w-[45px] lg:w-[65px] mb-6 lg:mb-0 lg:mr-4"
			/>
			<ul className="pl-4 list-disc">
				<li>本遊戲分類為限制級，須滿18歲始得購買或使用。</li>
				<li>請注意遊戲時間，避免沉迷。遊戲虛擬情節請勿模仿。</li>
				<li>
					本遊戲為免費使用，但遊戲部分內容或服務需另行支付其他費用，請依個人興趣、能力進行購買，請勿過度消費。
				</li>
				<li>
					請閱讀、瞭解並同意「使用者服務條款」及「個人資料暨隱私政策條款」之所有內容後，方得使用本遊戲服務。
				</li>
			</ul>
		</section>
	);
}
