import { Logo } from "components/Logo";
import { Reserve } from "components/Reserve";
import { SNSButtons } from "components/SNSButtons";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React, { Suspense, useMemo } from "react";

const ParallaxHero = React.lazy(() =>
	import("components/ParallaxHero").then((module) => ({
		default: module.ParallaxHero,
	}))
);

export const Footer = () => {
	const { t } = useTranslation();
	const navItems = useMemo(
		() => [
			{
				display: t("translation:privacy"),
				link: t("translation:policy_link"),
			},
			{
				display: t("translation:tos"),
				link: t("translation:policy_link"),
			},
			{
				display: t("translation:contact_us"),
				link: "mailto:ask-starlusts@pinkcore.com",
			},
		],
		[t]
	);

	// noinspection AllyJsxHardcodedStringInspection
	return (
		<div className="md:grid -mt-[20vw]">
			<Suspense
				fallback={
					<StaticImage
						src="../images/ready-bg.png"
						alt=""
						layout="fullWidth"
						className="hidden col-span-full row-span-full -z-10 md:block"
					/>
				}
			>
				<ParallaxHero />
			</Suspense>
			<div className="contents md:flex md:flex-col col-span-full row-span-full">
				<div className="grid">
					<StaticImage
						src="../images/ready-bg-m.png"
						alt=""
						layout="fullWidth"
						placeholder="blurred"
						className="col-span-full row-span-full -z-10 md:hidden"
					/>
					<section className="col-span-full row-span-full flex flex-col items-center pt-[30vw] md:pt-[18vw]">
						<h2
							className="text-shadow-purple font-bold leading-none italic text-[25px] text-center
							[&_strong]:text-[36px] md:text-[68px] md:[&_strong]:text-[100px]"
						>
							<Trans i18nKey="heading.ready" />
						</h2>
					</section>
				</div>
				<footer className="grid -mt-[20vw] md:block md:mt-auto">
					<StaticImage
						src="../images/footer-bg-m.png"
						alt=""
						layout="fullWidth"
						objectPosition="50% 100%"
						className="col-span-full row-span-full -z-20 md:hidden"
					/>
					<div className="flex flex-col pb-4 col-span-full row-span-full md:pt-0">
						<div className="mx-auto mb-4 pt-[20vw] md:pt-0">
							<Reserve />
						</div>
						<nav className="flex justify-center space-x-4">
							<SNSButtons />
						</nav>
						<div className="flex items-center justify-center mt-auto mb-2 md:my-8 lg:mt-8">
							<div className="w-[195px]">
								<Logo />
							</div>
							<StaticImage
								src="../images/logo-ice-cone.png"
								alt="Ice cone logo"
								width={91}
							/>
						</div>
						<nav>
							<ul className="flex justify-center mb-2 text-xs leading-none divide-x">
								{navItems.map((item) => (
									<li key={item.display} className="px-1">
										<a
											href={item.link}
											target="_blank"
											rel="noreferrer"
											className="text-white"
										>
											{item.display}
										</a>
									</li>
								))}
							</ul>
						</nav>
						<small className="block text-xs text-center">
							©2023 IceCone Studio / PINKCORE
							<br />
							Star Lusts™ All rights reserved.
						</small>
					</div>
				</footer>
			</div>
		</div>
	);
};
