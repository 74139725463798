import { StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Links } from "libs/constants";
import React from "react";

export const SpaceShip = () => {
	const { t, language } = useI18next();

	return (
		<a
			href={language === 'en' ? Links.DownloadEn : Links.Download}
			target="_blank"
			rel="noreferrer noopener"
			className="hidden lg:flex justify-center group fixed bottom-4 right-0 z-10 pb-4 w-[234px] h-[136px]"
		>
			<StaticImage
				src="../images/space-ship-beam.png"
				alt=""
				layout="fixed"
				width={234}
				height={416}
				className="hidden !absolute bottom-9 left-1/2 -translate-x-1/2 group-hover:block"
			/>
			<StaticImage
				src="../images/space-ship.png"
				alt=""
				layout="fixed"
				width={181}
				height={136}
				className="!absolute bottom-0 left-1/2 -translate-x-1/2"
			/>
			<span className="stroked-download mt-auto leading-normal text-[28px] font-bold relative z-10">
				{t("button.download")}
			</span>
		</a>
	);
};
