import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useI18next } from "gatsby-plugin-react-i18next";

const MetadataQuery = graphql`
	query Metadata {
		site {
			siteMetadata {
				host: url
			}
		}
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "metadata" } } }
		) {
			edges {
				node {
					id
					fields {
						language
					}
					frontmatter {
						title
						description
						image {
							publicURL
						}
					}
				}
			}
		}
	}
`;

export const Seo = () => {
	const {
		languages,
		language,
		originalPath,
		defaultLanguage,
		siteUrl = "http://localhost:8000",
	} = useI18next();
	const data = useStaticQuery<Queries.MetadataQuery>(MetadataQuery);
	const { edges } = data.allMarkdownRemark;
	const { frontmatter } = edges.find(
		({ node }) => node.fields?.language === language
	)!.node;
	const metadata = {
		...frontmatter,
		image: `${data?.site?.siteMetadata?.host}${frontmatter?.image?.publicURL}`,
	};
	const createUrlWithLang = (lng: string) => {
		const url = `${siteUrl}${
			lng === defaultLanguage ? "" : `/${lng}`
		}${originalPath}`;
		return url.endsWith("/") ? url : `${url}/`;
	};

	return (
		<GatsbySeo
			title={metadata?.title ?? ""}
			description={metadata?.description ?? ""}
			canonical={createUrlWithLang(language)}
			language={language}
			languageAlternates={[
				...languages.map((lng) => ({
					hrefLang: lng,
					href: createUrlWithLang(lng),
				})),
				{
					hrefLang: "x-default",
					href: createUrlWithLang(defaultLanguage),
				},
			]}
			openGraph={{
				type: "game",
				title: metadata?.title ?? "",
				description: metadata?.description ?? "",
				site_name: metadata?.title ?? "",
				images: [
					{
						url: metadata?.image ?? "",
					},
				],
			}}
			twitter={{
				cardType: "summary_large_image",
			}}
		/>
	);
};
