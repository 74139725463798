import * as React from "react";

type Props = {
	children?: React.ReactNode;
};

export const GradientCoverContainer: React.FC<Props> = ({ children }) => {
	return (
		<div className="lg:relative">
			{children}
			<div className="hidden lg:block absolute inset-0 bg-[url(/images/gradient-cover.png)] bg-[length:100%_auto] bg-repeat-y pointer-events-none" />
		</div>
	);
};
