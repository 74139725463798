exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-announcements-tsx": () => import("./../../../src/pages/announcements.tsx" /* webpackChunkName: "component---src-pages-announcements-tsx" */),
  "component---src-pages-characters-tsx": () => import("./../../../src/pages/characters.tsx" /* webpackChunkName: "component---src-pages-characters-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-announcement-tsx": () => import("./../../../src/templates/announcement.tsx" /* webpackChunkName: "component---src-templates-announcement-tsx" */),
  "component---src-templates-character-tsx": () => import("./../../../src/templates/character.tsx" /* webpackChunkName: "component---src-templates-character-tsx" */)
}

