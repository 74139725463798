import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as CrossIcon } from "icons/cross.svg";

type Props = {
	children: React.ReactNode;
	showClose?: boolean;
	showInnerClose?: boolean;
	open: boolean;
	onClose: () => void;
};

export const Modal: React.FC<Props> = ({
	children,
	open,
	showInnerClose,
	showClose,
	onClose,
}) => {
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog as="div" className="relative z-30" onClose={onClose}>
				<Transition.Child
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black/80" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					{showClose && (
						<button
							type="button"
							className="absolute z-40 text-white right-6 top-6"
							onClick={onClose}
						>
							<CrossIcon width={33} height={33} />
						</button>
					)}
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="text-white w-full max-w-[650px] transform overflow-hidden align-middle font-bold">
								{showInnerClose && (
									<button
										type="button"
										className="absolute z-40 text-white right-8 top-10 md:right-12 md:top-14"
										onClick={onClose}
									>
										<StaticImage
											src="../images/btn-x.png"
											alt="Close"
											width={33}
											height={34}
										/>
									</button>
								)}
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export const ModalPanel: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	return (
		<div className="grid">
			<StaticImage
				src="../images/popup-bg-m.png"
				alt=""
				layout="fullWidth"
				className="col-span-full row-span-full md:hidden"
			/>
			<StaticImage
				src="../images/popup-bg.png"
				alt=""
				layout="fullWidth"
				className="hidden col-span-full row-span-full md:block"
			/>
			<div className="z-0 flex px-4 py-8 col-span-full row-span-full md:px-8">
				<div className="m-auto">{children}</div>
			</div>
		</div>
	);
};
