// noinspection AllyPlainJsInspection

import cx from "classnames";
import React from "react";

type Variant = "primary" | "secondary";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	variant?: Variant;
	stretch?: boolean;
	loading?: boolean;
};

const theme: Record<Variant, Record<string, string>> = {
	primary: {
		text: "bg-[#821572] group-disabled:bg-[#808080]",
		prefix: "border-r-[#821572] group-disabled:border-r-[#808080]",
		suffix: "border-l-[#821572] group-disabled:border-l-[#808080]",
	},
	secondary: {
		text: "bg-[#808080]",
		prefix: "border-r-[#808080]",
		suffix: "border-l-[#808080]",
	},
};

export const Button: React.FC<Props> = ({
	variant = "primary",
	stretch,
	loading,
	className,
	children,
	...props
}) => {
	const classes = theme[variant];
	return (
		<button
			disabled={loading}
			type="button"
			className={cx(
				"transition text-lg inline-flex group disabled:cursor-not-allowed",
				"outline-none focus:outline-none",
				{ "animation-pulse": loading }
			)}
			{...props}
		>
			<span
				className={cx("h-full border-8 border-transparent", classes.prefix)}
			/>
			<span className={cx("p-3 text-white", classes.text, className)}>
				{children}
			</span>
			<span
				className={cx("h-full border-8 border-transparent", classes.suffix)}
			/>
		</button>
	);
};
