import cx from "classnames";
import { Modal } from "components/Modal";
import { SNSButtons } from "components/SNSButtons";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { ReactComponent as MenuIcon } from "icons/burger.svg";
import { ReactComponent as DiscordIcon } from "icons/discord.svg";
import { ReactComponent as FacebookIcon } from "icons/facebook.svg";
import { ReactComponent as GlobeIcon } from "icons/globe.svg";
import { ReactComponent as TwitterIcon } from "icons/twitter.svg";
import { Links } from "libs/constants";
import React, { useState } from "react";

import { Disclosure } from "@headlessui/react";
import { useMediaQuery } from "@react-hookz/web";

// noinspection AllyPlainJsInspection
const LANGUAGES = [
	{ display: "繁", language: "zh-tw" },
	{ display: "简", language: "zh-cn" },
	{ display: "EN", language: "en" },
	{ display: "日", language: "ja" },
	{ display: "한", language: "ko" },
];

const languageButtons: Record<
	string,
	{ default: React.ReactNode; active: React.ReactNode }
> = {
	"zh-tw": {
		default: <StaticImage src="../images/button-zh-tw.png" alt="繁" />,
		active: <StaticImage src="../images/button-active-zh-tw.png" alt="繁" />,
	},
	"zh-cn": {
		default: <StaticImage src="../images/button-zh-cn.png" alt="简" />,
		active: <StaticImage src="../images/button-active-zh-cn.png" alt="简" />,
	},
	en: {
		default: <StaticImage src="../images/button-en.png" alt="EN" />,
		active: <StaticImage src="../images/button-active-en.png" alt="EN" />,
	},
	ja: {
		default: <StaticImage src="../images/button-ja.png" alt="日" />,
		active: <StaticImage src="../images/button-active-ja.png" alt="日" />,
	},
	ko: {
		default: <StaticImage src="../images/button-ko.png" alt="한" />,
		active: <StaticImage src="../images/button-active-ko.png" alt="한" />,
	},
};

type TLink = { display: string; link: Links };

export const Header = () => {
	const { t, language, changeLanguage } = useI18next();
	const navItems: TLink[] = [
		{ display: t("nav.home"), link: Links.Home },
		{ display: t("nav.announcements"), link: Links.Announcements },
		{
			display: t("nav.world_overview"),
			link: Links.WorldOverview,
		},
		{ display: t("nav.characters"), link: Links.Characters },
		{ display: t("nav.feature"), link: Links.Feature },
		{ display: t("nav.faq"), link: Links.FAQ },
	];
	const shouldChangeLayout = useMediaQuery(
		`(min-width: ${language === "en" ? 1424 : 1298}px)`
	);
	const [languageOpen, setLanguageOpen] = useState(false);
	const handleLanguageClose = () => setLanguageOpen(false);
	const otherLanguages = LANGUAGES.filter(({ language: l }) => l !== language);

	return (
		<>
			<header
				className={cx(
					"fixed top-0 inset-x-0 flex items-center justify-end p-4 z-20",
					{
						"lg:px-5 lg:bg-black/50 lg:justify-center lg:py-2":
							shouldChangeLayout,
					}
				)}
			>
				<a
					href={language === 'en' ? Links.DownloadEn : Links.Download}
					target="_blank"
					rel="noreferrer"
					className="px-3 py-1 mr-3 text-white rounded-md lg:hidden bg-primary"
				>
					{t("button.download")}
				</a>
				<button
					type="button"
					className={cx("mr-2", { "lg:hidden": shouldChangeLayout })}
					onClick={() => setLanguageOpen(true)}
				>
					<MenuIcon width={32} height={25} />
				</button>
				<div className={cx("hidden", { "lg:block": shouldChangeLayout })}>
					<Nav items={navItems} />
				</div>
				<Disclosure>
					{({ open, close }) => (
						<div
							className={cx("hidden absolute right-0 top-0", {
								"lg:inline-grid": shouldChangeLayout,
							})}
						>
							<StaticImage
								src="../images/nav-bg.png"
								alt=""
								layout="fullWidth"
								className={cx("col-span-full" + " row-span-full", {
									hidden: open,
								})}
							/>
							<StaticImage
								src="../images/nav-bg-active.png"
								alt=""
								layout="fullWidth"
								className={cx("col-span-full" + " row-span-full", {
									hidden: !open,
								})}
							/>
							<div
								className={cx(
									"grid col-span-full row-span-full pr-4 pl-5 pt-3",
									{ "grid-rows-2": open },
									open ? "pb-8" : "pb-3"
								)}
							>
								<nav className="flex row-span-1 row-start-1 space-x-4 col-span-full">
									<Disclosure.Button className="group w-[50px] h-[50px]">
										<span className="group-hover:hidden">
											{languageButtons[language].default}
										</span>
										<span className="hidden group-hover:inline">
											{languageButtons[language].active}
										</span>
									</Disclosure.Button>
									<SNSButtons />
								</nav>
								<Disclosure.Panel className="row-span-1 row-start-2 col-span-full">
									<nav className="flex space-x-4">
										{otherLanguages.map(({ language: l }) => (
											<button
												key={l}
												type="button"
												className="group w-[50px] h-[50px]"
												onClick={async () => {
													if (l === 'ja') window.location.href = 'https://dmg.starlusts.com/';
													changeLanguage(l);
													close();
												}}
											>
												<span className="group-hover:hidden">
													{languageButtons[l].default}
												</span>
												<span className="hidden group-hover:inline">
													{languageButtons[l].active}
												</span>
											</button>
										))}
									</nav>
								</Disclosure.Panel>
							</div>
						</div>
					)}
				</Disclosure>
			</header>
			<Modal
				showClose
				open={languageOpen && !shouldChangeLayout}
				onClose={handleLanguageClose}
			>
				<div className="px-9">
					<nav>
						<ul className="flex flex-col items-center space-y-8">
							{navItems.map((props) => (
								<li key={props.display}>
									<Link
										to={props.link}
										onClick={handleLanguageClose}
										className="text-xl text-primary"
									>
										{props.display}
									</Link>
								</li>
							))}
						</ul>
					</nav>
					<hr className="mx-auto w-full max-w-[300px] border-primary my-10" />
					<nav>
						<ul className="flex items-center justify-center space-x-12">
							<li>
								<SNSItem href={Links.LinkTree}>
									<FacebookIcon width={16} height={34} />
								</SNSItem>
							</li>
							<li>
								<SNSItem href={Links.LinkTree}>
									<TwitterIcon width={36} height={26} />
								</SNSItem>
							</li>
							<li>
								<SNSItem href={Links.LinkTree}>
									<DiscordIcon width={32} height={25} />
								</SNSItem>
							</li>
						</ul>
						<div className="flex items-center justify-center mt-9">
							<div>
								<GlobeIcon width={25} height={25} />
							</div>
							<div className="divide-x-2 contents divide-primary">
								{LANGUAGES.map((props) => (
									<span key={props.display} className="px-3">
										<LanguageItem onClick={handleLanguageClose} {...props} />
									</span>
								))}
							</div>
						</div>
					</nav>
				</div>
			</Modal>
		</>
	);
};

const SNSItem = ({
	href,
	children,
}: {
	href: string;
	children: React.ReactNode;
}) => (
	<a href={href} target="_blank" rel="noreferrer" className="text-primary">
		{children}
	</a>
);

const LanguageItem: React.FC<
	typeof LANGUAGES[number] & { onClick: () => void }
> = ({ display, language, onClick }) => {
	const { changeLanguage } = useI18next();
	const handleClick = async () => {
		await changeLanguage(language);
		onClick();
	};

	return (
		<button
			type="button"
			className="text-xl text-primary"
			onClick={handleClick}
		>
			{display}
		</button>
	);
};

const Nav = (props: { items: TLink[] }) => {
	return (
		<nav>
			<ul className="flex flex-row divide-x divide-muted text-disabled border-x border-muted">
				{props.items.map(({ display, link }) => (
					<li key={display} className="px-6 leading-none">
						<Link to={link} className="text-lg leading-none hover:text-primary">
							{display}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};
