import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

export const Logo = (props: { positioned?: boolean }) => {
	if (!props.positioned) return <LogoIcon />;

	return (
		<div className="absolute left-0 top-1.5 lg:top-9 w-[163px] lg:w-[326px] z-10">
			<LogoIcon />
		</div>
	);
};

export const LogoIcon = () => {
	const { language } = useI18next();

	if (language === "en")
		return <StaticImage src="../images/logo-en.png" alt="Logo" />;

	return language === "ko" ? (
		<StaticImage src="../images/logo-ko.png" alt="Logo" />
	) : (
		<StaticImage src="../images/logo.png" alt="Logo" />
	);
};
