import * as React from "react";

export const Meteor = () => {
	return (
		<>
			{[...Array(14)].map((_, i) => (
				<div key={i} className={`meteor-${i + 1}`} />
			))}
		</>
	);
};
