import React from "react";
import { Dialog } from "@headlessui/react";
import { useSessionStorageValue } from "@react-hookz/web";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Modal, ModalPanel } from "components/Modal";
import { Button } from "components/Button";

export function AgeWarningModal() {
	const { t } = useTranslation();
	const [confirmed, setConfirmed] = useSessionStorageValue(
		"age_confirmed",
		false
	);
	const preventClose = () => {};
	const onConfirm = () => setConfirmed(true);
	const onCancel = () => {
		window.history.back();
	};

	return (
		<Modal open={!confirmed} onClose={preventClose}>
			<ModalPanel>
				<div className="space-y-4">
					<Dialog.Title className="text-2xl">
						{t("title.adult_warning")}
					</Dialog.Title>
					<Dialog.Description className="text-sm">
						{t("description.adult_warning")}
					</Dialog.Description>
				</div>

				<div className="flex justify-center mx-auto my-4 space-x-2 sm:my-6 md:my-9">
					<Button onClick={onConfirm}>{t("button.over_18")}</Button>
					<Button variant="secondary" onClick={onCancel}>
						{t("button.under_18")}
					</Button>
				</div>
				<p className="text-xs text-left">{t("information.adult_warning")}</p>
			</ModalPanel>
		</Modal>
	);
}
