import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Links } from "libs/constants";

const items = [
	{
		link: Links.LinkTree,
		default: <StaticImage src="../images/button-twitter.png" alt="Twitter" />,
		active: (
			<StaticImage src="../images/button-active-twitter.png" alt="Twitter" />
		),
	},
	{
		link: Links.LinkTree,
		default: <StaticImage src="../images/button-facebook.png" alt="Facebook" />,
		active: (
			<StaticImage src="../images/button-active-facebook.png" alt="Facebook" />
		),
	},
	{
		link: Links.LinkTree,
		default: <StaticImage src="../images/button-discord.png" alt="Discord" />,
		active: (
			<StaticImage src="../images/button-active-discord.png" alt="Discord" />
		),
	},
];

export const SNSButtons = () => {
	return (
		<>
			{items.map(({ link, default: d, active: a }, index) => (
				<a
					key={index}
					href={link}
					rel="noreferrer"
					target="_blank"
					className="group w-[50px] h-[50px]"
				>
					<span className="group-hover:hidden">{d}</span>
					<span className="hidden group-hover:inline">{a}</span>
				</a>
			))}
		</>
	);
};
